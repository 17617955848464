@import url(https://fonts.googleapis.com/css?family=Krona+One&display=swap);
body {
    /* background */
    background: #757f9a;
    background: -webkit-gradient(linear, left top, right top, from(#757f9a), to(#d7dde8)) no-repeat center center fixed;
    background: linear-gradient(to right, #757f9a, #d7dde8) no-repeat center center fixed;

    /* fonts */
    font-family: 'Krona One', Arial, Helvetica, sans-serif;
}

/* Login page - Welcome.jsx */

#login {
    background-color: white;
    border-radius: 10px;
    padding: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 32px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

#login .logo { display: block; width: 300px; margin: 10px auto; }

#login-form { text-align: center; display: grid; }

#login-form > * { padding: 10px; margin: 2px; border-radius: 5px; }

#login-form > h1 { font-size: 40px; }

#login-form > span { font-size: 24px; }

/* Whiteboard page - Whiteboard.jsx */

#whiteboard {
    margin: 100px 250px;
    background-color: white;
    display: grid;
    height: 80vh;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:"main     sidebar"
                        "main     sidebar"
                        "main     sidebar"
                        "footer   footer";
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 3px 4px rgba(0,0,0,0.24);
    -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

#whiteboard main { grid-area:main; }

#whiteboard aside { grid-area:sidebar; height: 100%; overflow-y: scroll; }

#whiteboard footer { grid-area:footer; }

#whiteboard canvas {
    background-color: #f2eecb;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

/* Whiteboard page - Chat.jsx */

#chat {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    height: 100vh;
    width: 100vh;
    max-width: 100%;
    max-height: 100%;
    word-break: break-all;
}

#chat p {
    background-color: lightgray;
    border: none;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

#chat form > * {
    border-radius: 5px;
    width: 95.5%;
    padding: 10px;
    position: absolute;
    margin-left: 3px;
    bottom: 0;
}

#chat form { margin-top: 40px; margin-bottom: 10px; position: relative; }

aside { background-color: #333333; }

/* Whiteboard page - footer */

footer { background-color: #4d4646; }

footer > input { margin-left: 20px; }

footer { display: flex; justify-content: center; align-items: center; }


/* General */

button {
    margin: 10px;
    padding: 2px;
    background-color: lightgray;
    border: none;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

button:hover { background-color: #585858; }

button:active { box-shadow: 0 1px #585858; -webkit-transform: translateY(1px); transform: translateY(1px); }

*:focus { outline: none; }

.link {
    font-size: 0.75em;
    padding: 10px;
    text-decoration: none;
    color: black;
}

.left { float: left; }

.right { float: right; }

.error { color: #c02739; text-align: center; display: block; }

.color {
    display: inline-block;
    height: 70px;
    width: 70px;
    cursor: pointer;
}

.clear { text-align: center; padding-top: 35px; font-size: 30px; }
